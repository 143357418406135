
import { Component, Vue, Watch } from "vue-property-decorator";
import OrderCreationClient from "@/components/orderCreation/OrderCreationClient.vue";
import OrderCreationLocation from "@/components/orderCreation/OrderCreationLocation.vue";
import OrderCreationJobs from "@/components/orderCreation/OrderCreationJobs.vue";
import OrderCreationPeriods from "@/components/orderCreation/OrderCreationPeriods.vue";
import OrderCreationDetails from "@/components/orderCreation/OrderCreationDetails.vue";
import OrderCreationSelection from "@/components/orderCreation/OrderCreationSelection.vue";
import OrderBreadcrumb from "@/components/orderCreation/OrderBreadcrumb.vue";
import {
    ICertificationDB,
    ICompany,
    ICompanyDB,
    IMissionDB,
    LabelValue,
    MissionPeriod,
    MissionPositionFront,
    StoreAction,
    StoreMutation,
    TimeSlot,
} from "@/types";
import { Action, Getter, Mutation, State } from "vuex-class";
import clonedeep from "lodash.clonedeep";
import { showModal } from "@/helpers/callables";
import moment from "moment-timezone";
import { capitalize, toastError } from "@/helpers/commons";
import { MissionCommand, ROUTES } from "@/data";
import DateWarning from "@/components/modal/DateWarning.vue";
import TimetableWarning from "@/components/modal/TimetableWarning.vue";
import CancelMission from "@/components/modal/CancelMission.vue";
import MissionTile from "@/components/tileContent/MissionTile.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { mixins } from "vue-class-component";
import TitleManager from "@/mixins/TitleManager.vue";
import InlineField from "@/components/InlineField.vue";

@Component({
    name: "OrderCreation",
    components: { MissionTile, CancelMission, DateWarning, OrderBreadcrumb, Swiper, SwiperSlide, TimetableWarning, InlineField },
})
export default class OrderCreation extends mixins(TitleManager) {
    $refs!: {
        missionNameField: any;
    };

    @Action("actions/getMission") getMission!: StoreAction;
    @Action("actions/createMission") createMission!: StoreAction;
    @Action("actions/updateMission") updateMission!: StoreAction;
    @Action("actions/cancelMission") cancelMission!: StoreAction;
    @Action("actions/deleteMission") deleteMission!: StoreAction;
    @Action("actions/createCompany") createCompany!: StoreAction;
    @Action("actions/getCopyMissions") getCopyMissions!: StoreAction;
    @Action("actions/sendCommandMission") sendCommandMission!: StoreAction;
    @Action("actions/getGlobalConfiguration") getGlobalConfiguration!: StoreAction;

    @Getter("jobOptions") jobOptions!: LabelValue[];
    @Getter("isAgency") isAgency!: any;
    @Getter("isCompany") isCompany!: any;

    @State("currentCompany") currentCompany!: ICompanyDB;

    @Mutation("addLink") addLink!: StoreMutation;

    copyErrors: any = [];
    copyMissions: any = [];
    loading = false;
    innerLoading = false;
    copyLoading = false;
    currentStep = -1;
    titleEdition = false;
    displayPreStep = false;
    showCancelMission = false;
    showDeleteMission = false;
    showDateWarning = false;
    showTimetableWarning = false;
    selectedMission: any = null;
    defaultMissionName: string = <string>this.$t("page.orderCreation.title");
    copySearch = "";
    errors: any = {};
    globalErrors: any = [];
    timeout: any = 0;
    tmpMission: IMissionDB | any = null;
    capitalize = capitalize;
    mission: IMissionDB | null = null;
    steps!: any;
    pageTitle = this.$t("page.orderCreation.titlePage");
    lockNext: boolean = true;

    @Watch("copySearch")
    onCopySearch() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
            this.clientMissionSearch();
        }, 300);
    }

    @Watch("$route.query.missionId")
    onMissionId() {
        if (this.displayPreStep) {
            this.displayPreStep = false;
        }
        this.init();
    }

    @Watch("$route.query.new")
    onNew() {
        if (this.$route.query.new === "1") {
            this.resetQuery("new");
            this.init();
        }
    }

    @Watch("tmpMission", { deep: true })
    ontmpMission() {
        this.lockNext = this.theStep.lock ? this.theStep.lock() : true;
    }

    get hasActions() {
        return this.mission?.availableCommands?.length;
    }

    get hasCancelAction() {
        return this.hasActions && this.mission?.availableCommands.indexOf("cancel") !== -1;
    }

    get hasDeleteAction() {
        return this.hasActions && this.mission?.availableCommands.indexOf("delete") !== -1;
    }

    get hasUpdateAction() {
        return this.hasActions && this.mission?.availableCommands.indexOf("update") !== -1;
    }

    get clientName() {
        return this.mission?.client?.name || "";
    }

    get theStep() {
        return this.steps[this.currentStep] || {};
    }

    get missionId() {
        return this.$route.query?.missionId;
    }

    get lastMissionId() {
        // return localStorage.getItem('lastMissionId');
        return false;
    }

    get totalJobs() {
        let nb = 0;

        this.tmpMission.positions?.forEach((position: any) => {
            nb += parseInt(position.quantity);
        });

        return nb;
    }

    get totalWorkers() {
        if (this.isAgency) {
            return (
                this.tmpMission.workers?.filter((w: any) => {
                    return w.status !== "aborted";
                }) || []
            ).length;
        }
        return "";
    }

    missionLength() {
        let days = 0;

        if (this.tmpMission?.periods?.length) {
            let [min, max] = this.getMinMaxPeriods();
            let day = min;

            while (day < max) {
                const today = moment(day);
                const periodsMatch = !!this.tmpMission.periods.find((period: MissionPeriod) => {
                    if (period.weekendIncluded || (today.day() !== 0 && today.day() !== 6)) {
                        const start = moment(period.start).startOf("day");
                        const end = moment(period.end).endOf("day");

                        return moment.range(start, end).contains(today);
                    }
                });

                if (periodsMatch) {
                    days += 1;
                }

                day = moment(day).add(1, "day").startOf("day").valueOf();
            }
        }

        return moment.duration(days || 1, "days").humanize();
    }

    get realCurrentStep() {
        let step = 0;
        if (this.mission?.clientId || this.mission?.client?._id) {
            step = 1;
        }
        if (this.mission?.address?.city) {
            step = 2;
        }
        if (this.mission?.positions?.length) {
            step = 3;
        }
        if (this.mission?.periods?.length) {
            step = 4;
        }
        if (this.mission?.description?.length) {
            step = 5;
        }

        return step;
    }

    get defaultStartStep() {
        if (this.isCompany) {
            return 1;
        }
        return 0;
    }

    get nbInvisible() {
        return this.steps.filter((s: any) => s.invisible).length;
    }

    get visible() {
        return this.isAgency || (this.isCompany && this.currentStep < this.steps.length - this.nbInvisible);
    }

    get isCopy() {
        return this.$route?.query?.copy === "1";
    }

    getMinMaxPeriods() {
        let min = Infinity;
        let max = -Infinity;

        this.tmpMission.periods?.forEach((period: MissionPeriod) => {
            if (period.start < min) {
                min = moment(period.start).startOf("day").valueOf();
            }
            if (period.end > max) {
                max = moment(period.end).endOf("day").valueOf();
            }
        });

        return [min, max];
    }

    stepSelected(index: number) {
        this.currentStep = index;
        if (this.mission) {
            this.tmpMission = clonedeep(this.mission);
        }
        this.checkStep(true);
    }

    getJob(id: string) {
        return this.jobOptions.find((i: LabelValue) => i.value === id);
    }

    async submitSelectionStep() {
        this.loading = true;
        try {
            if (this.isAgency) {
                this.loading = false;
                await this.$router.push({ name: ROUTES.APP.MISSION._ROOT, params: { missionId: this.mission?._id || "" } });
            }
            if (this.isCompany) {
                if (this.tmpMission.agencyId) {
                    await this.saveMission({
                        agencyId: this.tmpMission.agencyId,
                    });
                    await this.$router.push({ name: ROUTES.APP.MISSION._ROOT, params: { missionId: this.mission?._id || "" } });
                }
            }
            return true;
        } catch (e) {
            console.log(e);
            this.loading = false;
            throw e;
        }
    }

    async submitDetailsStep(goNextStep: boolean = true) {
        this.loading = true;
        try {
            if (!this.tmpMission.internalAgencyNotes) {
                this.tmpMission.internalAgencyNotes = "-";
            }
            if (!this.tmpMission.description) {
                this.tmpMission.description = "-";
            }
            await this.saveMission(
                {
                    internalAgencyNotes: this.tmpMission.internalAgencyNotes,
                    description: this.tmpMission.description,
                    presentationMode: this.tmpMission.presentationMode,
                },
                undefined,
                undefined,
                goNextStep
            );
            this.loading = false;
            return true;
        } catch (e) {
            console.log(e);
            this.loading = false;
            throw e;
        }
    }

    async submitPeriodsStep(_fromModal?: boolean) {
        this.loading = true;
        try {
            this.tmpMission.periods?.forEach((period: MissionPeriod) => {
                if (period.slots) {
                    Object.keys(period.slots).forEach((key: string) => {
                        period.slots[key].forEach((slot: TimeSlot) => {
                            // noinspection SuspiciousTypeOfGuard
                            if (typeof slot.from === "string") {
                                slot.from = parseInt((<string>slot.from).replace(":", ""));
                            }
                            // noinspection SuspiciousTypeOfGuard
                            if (typeof slot.to === "string") {
                                slot.to = parseInt((<string>slot.to).replace(":", ""));
                            }
                        });
                    });
                }
            });
            await this.saveMission({ periods: this.tmpMission.periods });
            this.loading = false;
            return true;
        } catch (e) {
            console.log(e);
            // if (e?.errors?.periods?.id === "invalid") {
            //     if (!this.errors[index]) {
            //         this.errors[index] = {};
            //     }
            //     this.errors[index].end = {id: "Invalid.EndDateInferior"};
            // }
            this.loading = false;
            throw e;
        }
    }

    async submitJobsStep() {
        this.loading = true;
        try {
            const clone = clonedeep(this.tmpMission);
            let unreadable = false;
            clone.positions?.forEach((position: any) => {
                if (position.jobName && position.jobName[0] && position.jobName[0].value !== null && position.jobName[0].value !== undefined) {
                    const jobId: any = position.jobName[0].value;
                    position.jobName = position.jobName[0].label;

                    if (typeof jobId === "number" && isNaN(jobId)) {
                        position.jobId = undefined;
                    }

                    if (typeof jobId === "string" && jobId.length) {
                        position.jobId = jobId;
                    }

                    if (position.certifications?.length) {
                        position.certifications = position.certifications.map((cert: LabelValue) => {
                            return (typeof cert.value === "number" && isNaN(cert.value) && cert.label) || cert.value;
                        });
                    } else if (position.certifications) {
                        position.certifications = null;
                    }
                } else {
                    console.log("unreadable job");
                    unreadable = true;
                }
            });
            if (!unreadable) {
                await this.saveMission({ positions: clone.positions, workers: clone.workers, periods: clone.periods });
                await this.getGlobalConfiguration();
                this.loading = false;
                return true;
            }
            this.loading = false;
        } catch (e) {
            console.log(e);
            this.loading = false;
            throw e;
        }
    }

    async submitLocationStep() {
        this.loading = true;
        try {
            let newAddress = null;
            if (!this.tmpMission.address) {
                this.tmpMission.address = { street: "", city: "", zip: "" };
            }
            if (!this.tmpMission.address.location?.length) {
                this.tmpMission.address.location = undefined;
            }
            if (
                !this.mission?.address ||
                (this.mission && JSON.stringify(this.mission.address?.location) !== JSON.stringify(this.tmpMission.address.location))
            ) {
                newAddress = this.tmpMission.address;
            }
            await this.saveMission({ address: newAddress });
            this.loading = false;
            return true;
        } catch (e) {
            console.log(e);
            this.loading = false;
            throw e;
        }
    }

    async submitClientStep() {
        this.loading = true;
        try {
            if (this.tmpMission.createNewClient && this.tmpMission.newClient) {
                const company: ICompanyDB = await this.saveCompany(this.tmpMission.newClient);
                if (!this.tmpMission.name || this.tmpMission.name === this.defaultMissionName) {
                    // @ts-ignore;
                    this.tmpMission.name = company.name || "";
                }
                this.tmpMission.newClient = {
                    name: "",
                    addresses: [],
                    contacts: [],
                    businessSectorId: "",
                    numbers: [],
                    agencyId: "",
                };
                this.tmpMission.createNewClient = false;
                await this.saveMission({ clientId: company._id });
            } else {
                await this.saveMission({ clientId: this.tmpMission.clientId });
            }
            this.loading = false;
            return true;
        } catch (e) {
            console.log(e);
            this.loading = false;
            throw e;
        }
    }

    async nextStep() {
        try {
            await this.theStep.submit();
        } catch (e) {
            console.log(e);
            if (e.errors?.period?.id === "same") {
                if (!this.errors[e.errors.period.data.periodIndexA]) {
                    this.errors[e.errors.period.data.periodIndexA] = {};
                }
                if (!this.errors[e.errors.period.data.periodIndexB]) {
                    this.errors[e.errors.period.data.periodIndexB] = {};
                }
                this.errors[e.errors.period.data.periodIndexA].period = { id: "Period.Same" };
                this.errors[e.errors.period.data.periodIndexB].period = { id: "Period.Same" };
                setTimeout(() => {
                    showModal.call(this, "DateWarning");
                }, 300);
            } else if (e.errors?.timetable?.id === "missing") {
                this.errors.periodIndex = e.errors.timetable.data.periodIndex;
                this.errors.jobId = e.errors.timetable.data.jobId;
                setTimeout(() => {
                    showModal.call(this, "TimetableWarning");
                }, 300);
            } else {
                this.errors = e.errors || {};
            }
        }
    }

    selectTitle() {
        this.titleEdition = true;
        this.$nextTick(() => {
            this.$refs.missionNameField.$el.querySelector("input").focus();
        });
    }

    async stopSelectTitle() {
        this.titleEdition = false;

        if (this.tmpMission?.name === "") {
            this.tmpMission.name = this.defaultMissionName;
        }

        if (this.mission?._id) {
            if (this.mission?.name !== this.tmpMission?.name) {
                await this.saveMission({}, true, true);
            }
        }
    }

    resetQuery(name: string) {
        const query: any = clonedeep(this.$route.query);
        delete query[name];
        this.$router.replace({ name: ROUTES.APP.MISSIONCREATION, query });
    }

    setSteps() {
        this.steps = [
            {
                label: this.$t("page.orderCreation.steps.client.label"),
                displayedValue: "",
                component: OrderCreationClient,
                submit: this.submitClientStep,
                save: () => {},
                invisible: false,
                lock: () => {
                    if (this.tmpMission.newClient?.name?.length || this.tmpMission?.client?._id) return false;
                    return true;
                },
            },
            {
                label: this.$t("page.orderCreation.steps.location.label"),
                displayedValue: "",
                component: OrderCreationLocation,
                submit: this.submitLocationStep,
                save: () => {},
                lock: () => {
                    return !this.tmpMission?.address?.location || this.tmpMission?.address?.location.length !== 2;
                },
            },
            {
                label: this.$t("page.orderCreation.steps.jobs.label"),
                displayedValue: "",
                component: OrderCreationJobs,
                submit: this.submitJobsStep,
                save: () => {},
                lock: () => {
                    return !this.tmpMission?.positions?.every((position: any) => position.jobName?.length);
                },
            },
            {
                label: this.$t("page.orderCreation.steps.periods.label"),
                displayedValue: "",
                component: OrderCreationPeriods,
                submit: this.submitPeriodsStep,
                save: () => {},
                lock: () => {
                    return false;
                },
            },
            {
                label: this.$t("page.orderCreation.steps.details.label"),
                displayedValue: "",
                component: OrderCreationDetails,
                submit: this.submitDetailsStep,
                save: () => {
                    this.submitDetailsStep(false);
                },
                lock: () => {
                    return false;
                },
            },
            {
                label: this.$t("page.orderCreation.steps.selection.label"),
                displayedValue: "",
                component: OrderCreationSelection,
                submit: this.submitSelectionStep,
                save: () => {},
                lock: () => {
                    return false;
                },
            },
        ];
    }

    setTmpMission() {
        this.tmpMission = {
            _id: "",
            name: this.defaultMissionName,
            newClient: {
                name: "",
                addresses: [],
                contacts: [],
                businessSectorId: "",
                numbers: [],
                agencyId: "",
            },
            client: {
                _id: null,
                name: "",
                addresses: [],
                contacts: [],
                businessSectorId: "",
                numbers: [],
                agencyId: "",
            },
            clientId: "",
            address: {
                street: "",
                city: "",
                zip: "",
                location: undefined,
            },
            positions: [],
            periods: [],
            description: "",
            internalAgencyNotes: "",
            presentationMode: false,
            clientValidation: false,
            warnings: [],
            createdAt: 0,
            updatedAt: 0,
        };
    }

    confirmCancelMission() {
        showModal.call(this, "CancelMission");
    }

    confirmDeleteMission() {
        showModal.call(this, "DeleteMission");
    }

    onCommand(result: any) {
        this.mission = result;
        if (this.mission) {
            this.tmpMission = clonedeep(this.mission);

            if (this.isAgency) {
                this.theStep.displayedValue = this.totalWorkers + "/" + this.totalJobs;
            } else {
                this.theStep.displayedValue = "";
            }
        }
    }

    selectMission(mission: any) {
        this.selectedMission = mission;
    }

    async checkCompany() {
        try {
            let clientId = this.mission?.client?._id || this.mission?.clientId;

            if (this.isCompany && !clientId) {
                this.tmpMission.clientId = this.currentCompany._id;
                this.tmpMission.name = <string>this.$t("page.orderCreation.commandDefaultName");
                this.currentStep = 1;
            }
            if (this.isCompany && this.mission?.agencyId) {
                await this.$router.push({ name: ROUTES.APP.MISSION._ROOT, params: { missionId: this.mission?._id } });
            }
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    async submitCancelMission(data: any) {
        this.innerLoading = true;
        try {
            const d: any = { cancelReason: data.reason };

            if (data.reason === "other") {
                d.cancelReasonOther = data.reasonText;
            }
            await this.sendCommand(MissionCommand.cancel, d);
            this.$modal.hide("CancelMission");
            await this.$router.push("/");
        } catch (e) {
            console.log(e);
        }
        this.innerLoading = false;
    }

    async sendCommand(command: string, data?: any) {
        this.innerLoading = true;
        try {
            const result = await this.sendCommandMission({
                missionId: this.mission?._id,
                command,
                data,
            });
            this.$emit("command", result);
        } catch (e) {
            console.log(e);
            // @ts-ignore;
            this.$toast.open({
                message: toastError(e),
                type: "error",
                duration: 10000,
            });
        }
        this.innerLoading = false;
    }

    async submitDeleteMission() {
        this.innerLoading = true;
        try {
            await this.deleteMission(this.mission?._id);
            // localStorage.setItem('lastMissionId', "");
            this.$modal.hide("DeleteMission");
            await this.$router.push("/");
        } catch (e) {
            console.log(e);
        }
        this.innerLoading = false;
    }

    async saveCompany(company: ICompanyDB | ICompany) {
        this.loading = true;
        try {
            this.loading = false;
            return await this.createCompany(company);
        } catch (e) {
            this.loading = false;
            throw e;
        }
    }

    async clientMissionSearch() {
        this.copyLoading = true;
        try {
            if (this.copySearch && this.copySearch.length >= 3) {
                this.copyMissions = await this.getCopyMissions({ slug: this.copySearch });
                this.copyMissions = this.copyMissions.filter((a: any) => a.missions?.length);
            }
        } catch (e) {
            console.log(e);
            this.copyErrors = e._global;
        }
        this.copyLoading = false;
    }

    async copyMission() {
        this.copyLoading = true;
        try {
            const finalData: any = this.selectedMission;

            this.mission = await this.sendCommandMission({
                missionId: finalData._id,
                command: "copy",
                finalData,
            });
            await this.$router.push({ name: ROUTES.APP.MISSIONCREATION, query: { missionId: this.mission?._id, copy: "1" } });
            this.loading = false;
        } catch (e) {
            console.log(e);
            this.copyErrors = e._global;
        }
        this.copyLoading = false;
    }

    async saveMission(data: any, silent?: boolean, onlyName?: boolean, goNextStep: boolean = true) {
        this.errors = {};
        this.globalErrors = [];
        if (!silent) {
            this.loading = true;
        }
        try {
            const finalData: any = {
                ...data,
                name: this.tmpMission.name,
            };
            if (this.tmpMission?.name === this.defaultMissionName) {
                finalData.name = this.tmpMission?.client?.name || "";
            }
            if (this.tmpMission?.name === "") {
                finalData.name = this.tmpMission?.client?.name || "";
            }
            if (!this.mission?._id) {
                if (this.isCompany) {
                    this.mission = await this.createMission({
                        clientId: this.currentCompany._id,
                        name: this.tmpMission.name,
                    });
                    await this.saveMission(finalData);
                } else {
                    this.mission = await this.createMission(finalData);
                }
            } else {
                this.mission = await this.updateMission({
                    missionId: this.mission._id,
                    data: finalData,
                });
            }

            if (this.mission) {
                if (onlyName) {
                    this.tmpMission.name = this.mission.name;
                } else {
                    this.tmpMission = clonedeep(this.mission);
                }
                await this.addLink({
                    label: capitalize(this.mission.name),
                    icon: "target",
                    route: { name: ROUTES.APP.MISSIONCREATION, query: { missionId: this.mission._id } },
                    value: this.mission._id + "-edit",
                });

                if (onlyName) {
                    return true;
                }
            }

            if (goNextStep) await this.checkStep();

            return true;
        } catch (e) {
            this.loading = false;
            throw e;
        }
    }

    async checkStep(dontResetIndex?: boolean) {
        if (this.mission?.client?._id) {
            this.steps[0].displayedValue = this.mission.client.name;
        }

        if (this.mission?.address?.city) {
            this.steps[1].displayedValue = this.mission.address.city;
        }

        if (this.mission?.positions?.length) {
            if (this.mission.positions.length === 1) {
                this.steps[2].displayedValue = this.mission.positions[0].job?.name;
            } else {
                this.steps[2].displayedValue = this.mission.positions.length + " " + this.$t("form.posts");
            }
            this.tmpMission.positions?.forEach((position: any) => {
                position.jobName = [this.getJob(<string>position.jobId)];
                if (position.certifications) {
                    position.certifications = position.certifications.map((certification: ICertificationDB) => ({
                        label: certification.name,
                        value: certification._id,
                    }));
                }
            });
        }

        if (this.mission?.periods?.length) {
            this.steps[3].displayedValue = this.missionLength();
        }

        if (this.mission?.description?.length) {
            this.steps[4].displayedValue = this.mission.description;
        }

        if (this.mission?.workers) {
            if (this.isAgency) {
                this.steps[5].displayedValue = this.totalWorkers + "/" + this.totalJobs;
            } else {
                this.steps[5].displayedValue = "-";
            }
        }

        if (!dontResetIndex) {
            this.currentStep = this.realCurrentStep;
        }
    }

    async init() {
        this.loading = true;
        this.setSteps();
        this.setTmpMission();
        this.mission = null;
        this.$forceUpdate();
        try {
            let missionId;

            if (this.isCompany) {
                this.steps[0].invisible = true;
            }

            if (this.missionId) {
                missionId = this.missionId;
            } else if (this.lastMissionId) {
                missionId = this.lastMissionId;
            }
            if (missionId) {
                this.mission = await this.getMission(missionId);
                if (this.mission) {
                    this.tmpMission = clonedeep(this.mission);
                    this.tmpMission.newClient = {
                        name: "",
                        addresses: [],
                        contacts: [],
                        businessSectorId: "",
                        numbers: [],
                        agencyId: "",
                    };

                    this.$set(this.tmpMission, "address", {});

                    await this.checkStep();
                    await this.addLink({
                        label: capitalize(this.mission.name),
                        icon: "target",
                        route: { name: ROUTES.APP.MISSIONCREATION, query: { missionId: this.mission._id } },
                        value: this.mission._id + "-edit",
                    });

                    if (this.isCopy) {
                        this.resetQuery("copy");
                        this.currentStep = this.isAgency ? 3 : 2;
                    }
                } else {
                    this.currentStep = this.defaultStartStep;
                }
            } else {
                this.currentStep = this.defaultStartStep;
            }
            await this.checkCompany();

            if (this.currentStep === 0 && (!this.mission || !this.mission._id)) {
                this.displayPreStep = true;
            }
        } catch (e) {
            console.log(e);
            this.currentStep = this.defaultStartStep;
            // localStorage.setItem('lastMissionId', "");
        }
        this.loading = false;
    }

    beforeMount() {
        if (this.$route.query.new === "1") {
            this.resetQuery("new");
        }
        this.init();
    }
}
