import { render, staticRenderFns } from "./WorkerCV.vue?vue&type=template&id=691225a8&scoped=true&"
import script from "./WorkerCV.vue?vue&type=script&setup=true&lang=ts&"
export * from "./WorkerCV.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./WorkerCV.vue?vue&type=style&index=0&id=691225a8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "691225a8",
  null
  
)

export default component.exports