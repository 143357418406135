import { render, staticRenderFns } from "./WorkerBlackList.vue?vue&type=template&id=7f2930db&scoped=true&"
import script from "./WorkerBlackList.vue?vue&type=script&setup=true&lang=ts&"
export * from "./WorkerBlackList.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./WorkerBlackList.vue?vue&type=style&index=0&id=7f2930db&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f2930db",
  null
  
)

export default component.exports